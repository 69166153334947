import {
  AdvertisementSystem,
  BlockPubArticleHeader,
  BlockPubHeaderMenu,
  BlockPubNavSqueeze,
  IframeAspectRatio,
  Paywall,
} from '@models/types'

export const TOP_STORY_MAX_NB_OF_PRI_ARTICLES = 6
export const DEFAULT_PLAN_TOGGLE_TO_YEAR = true
export const DEFAULT_AD_SYSTEM: AdvertisementSystem = 'googleAdManager'
export const DEFAULT_ARTICLE_ACCESS: Paywall = 'Public'
export const DEFAULT_EXTERNAL_DISPLAY: BlockPubArticleHeader['externalDisplay'] = 'topStory'
export const DEFAULT_ARTICLE_TYPE: BlockPubArticleHeader['type'] = 'default'
export const DEFAULT_IS_IFRAME: BlockPubArticleHeader['isIframe'] = false
export const DEFAULT_HEADER_MENU_LOGO_SIZE: NonNullable<BlockPubHeaderMenu['logoSize']> = 'small'
export const DEFAULT_NAV_SQUEEZE_LOGO_SIZE: NonNullable<BlockPubNavSqueeze['logoSize']> = 'small'
export const PUB_HEADER_AD_HERO_DEFAULT_VISIBILITY = false
export const DEFAULT_IFRAME_EXTERNAL_DISPLAY: IframeAspectRatio = 'landscape'
export const DEFAULT_GALLERY_EXTERNAL_DISPLAY: IframeAspectRatio = 'landscape'
export const PUBLICATION_INTERNAL_PAGE_SLUGS = {
  home: '/',
  subscribe: '/subscribe/',
  signIn: '/sign-in/',
  account: '/account/',
  payments: '/payments/',
  thankYou: '/thank-you/',
  terms: '/terms/',
  privacyPolicy: '/privacy-policy/',
  latestStories: '/latest-stories/',
  search: '/search/',
  contactUs: '/contact-us/',
  notFound: '/404/',
  newsletters: '/newsletters/',
  tags: '/tags',
  writers: '/writers',
  sections: '/sections',
}
